import { Injectable } from '@angular/core';

export class service{
    constructor() { }
  
getnews(){
  var news =[
  { Title:"Invitation for Rasa Sanje Programme 2016", newsId:'Rasasanje-2016'},
  { Title:"Observing Rashtriya Ekta Diwas", newsId:'Rashtriya-Ekta-Diwas'},
  { Title:"Happy Deepavali", newsId:'happyDeepavali'},
  { Title:"Deepavali holidays & Kannada Rajyotsava Notification", newsId:'rajyotsava'},
  { Title:"Time table from 19th October 2016 to 22nd October 2016", newsId:'OctTimeTable'}
  ];
        return news;
    }
}