import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AcademicsComponent } from './academics/academics.component';
import { ContactComponent } from './contact/contact.component';
import { RouterModule, Routes, ActivatedRoute, ParamMap  } from '@angular/router';
import { CampusTourComponent } from './campus-tour/campus-tour.component';
import { NewsComponent } from './news/news.component';
import { CalendarOfEventsComponent } from './home/calendar-of-events/calendar-of-events.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home',   component:HomeComponent },
 
  { path: 'about', component:AboutComponent },
  { path: 'academics', component:AcademicsComponent },
  { path: 'contact', component:ContactComponent }, 
  { path: 'campusTour', component:CampusTourComponent },
  { path: 'news', component:NewsComponent},
  {path:'news/:newsId',component:NewsComponent},
  {path:'home/calendarOfEvents',component:CalendarOfEventsComponent}
];
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HeaderComponent,
    HomeComponent,
    AcademicsComponent,
    ContactComponent,
    CampusTourComponent,
    NewsComponent,
    CalendarOfEventsComponent,
   
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
