import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { NewsComponent } from '../news/news.component';
import { service } from '../service/service';

var news = [
  { Title:"Invitation for Rasa Sanje Programme 2016", newsId:'Rasasanje-2016'},
  { Title:"Observing Rashtriya Ekta Diwas", newsId:'Rashtriya-Ekta-Diwas'},
  { Title:"Happy Deepavali", newsId:'happyDeepavali'},
  { Title:"Deepavali holidays & Kannada Rajyotsava Notification", newsId:'rajyotsava'},
  { Title:"Time table from 19th October 2016 to 22nd October 2016", newsId:'OctTimeTable'}
];
class model{
 news:any[] 
}
@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [service]
})
@Injectable()
export class HomeComponent implements OnInit {
  news =[
    { Title:"Online classes", newsId:' online classes-2020'},
    
]; 
public m:model;
  constructor(private router: Router,public service:service) { 
  }
  ngOnInit() {
   
  }
  

  onClick(news){
  console.log("Title",news.Title);
  }

}
